<template>
  <div>

    <colom-chart
      :data="dataChart"
    />

    <div class="page-container-table">

      <!--Phần header của bảng -->
      <button-all-header
        :hideSearch="false"
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :arrayExcel="[]"
        :hideAdd="false"
        class="custtom-header pr-1 mb-1"
        @clickExportExcel="dowloadFile"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import ColomChart from './components/ColomChart.vue'

import store from '../../store'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstanstApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BCard,
    VueApexCharts,
    ColomChart,
  },
  data() {
    return {
      dataObj: {},
      dataChart: [],
      urlQuery: {
        key: '',
      },
      dataList: [],
      totalRecord: 0,
      columns: [
        {
          label: 'MỨC THU NHẬP',
          field: 'name',
        },
        {
          label: 'SỐ LƯỢNG LAO ĐỘNG ',
          field: 'value',

        },
      ],

    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async dowloadFile() {
      const name = 'ToanTinhTheoMucThuNhap.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    async fetchData() {
      this.$showLoading()
      const res = await axiosApiInstance.get(ConstantsApi.API_SALARY)
      this.dataList = res?.data?.data
      this.$hideLoading()
      const value = this.dataList.map(e => e.value)
      // const maxNumber = Math.max(...value)
      // while (maxNumber > 1) {
      //   // eslint-disable-next-line no-const-assign
      //   maxNumber /= 10
      // }

      this.dataChart = [
        {
          name: 'Số lượng toàn tỉnh trong độ tuổi lao động',
          data: value,
        },
      ]
    },
  },

}
</script>

<style lang="scss">

</style>
