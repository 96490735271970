<template>
  <div id="columnChart">
    <b-card no-body>
      <b-card-header>
        <b-card-title>Toàn tỉnh theo mức thu nhập</b-card-title>
      </b-card-header>

      <!-- chart -->
      <b-card-body>
        <vue-apex-charts
          type="bar"
          :options="chartOptions"
          :series="data"
          height="400px"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  // [35, 15, 18, 12, 20]
  data() {
    return {
      series: [

      ],
      chartOptions: {
        chart: {
          height: 400,
          type: 'bar',
          zoom: {
            enabled: false,
          },
          background: '#fff',
          fontFamily: 'Montserrat',
          foreColor: '#3E4651',
        },
        colors: ['#28C76F'],
        grid: {
          show: true,
          borderColor: '#D6D8DE',
          strokeDashArray: 0,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: 0,
            right: 20,
            bottom: 0,
            left: 10,
          },
        },
        plotOptions: {

          bar: {
            columnWidth: '12%',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },

        // 'Tân Yên', 'Bà Rịa'
        xaxis: {
          categories: ['Đến 5 triệu đồng', 'Trên 5 triệu đến 10 triệu', 'Trên 10 triệu đến 18 triệu', 'Trên 18 triệu đến 32 triệu', 'Trên 32 triệu đến 52 triệu', 'Trên 52 triệu đến 80 triệu', 'Trên 80 triệu'],
          tickAmount: 9,
          position: 'bottom',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tickPlacement: 'between',
          tooltip: {
            enabled: true,
          },
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            showDuplicates: false,
            trim: false,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Montserrat',
              fontWeight: 600,
            },
          },
        },

        yaxis: {
          min: 0,
          tickAmount: 10,
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            style: {
              fontSize: '12px',
            },
            formatter(val) {
              return `${val.toFixed(0)}`
            },
          },

        },
        tooltip: {
          enabled: true,
          intersect: false,
          x: {
            show: false,
          },
        },
        noData: {
          text: 'Không có dữ liệu',
          align: 'center',
          verticalAlign: 'middle',
          offsetY: -17,
          style: {
            color: '#8088f8',
            fontSize: '18px',
            fontFamily: 'Montserrat',
          },
        },
      },

    }
  },
  watch: {
    dataCol(val) {
      if (val) {
        this.series[0].data = Object.values(this.dataCol)
      }
    },
  },
  created() {
  },
}
</script>

<style lang="scss">
</style>
